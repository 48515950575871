
/**
 * Table whit all the profiles in the project
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import { Component, Prop, Watch, Mixins } from "vue-property-decorator";
import { mapGetters, mapActions } from "vuex";
import { Interval, ProjectProfile } from "@/interface/projectsInterface";
import Multiselect from "vue-multiselect";
import confirmModal from "@/mixins/confirmModal/confirmModal";

@Component({
  name: "IntervalDetailsTable",
  components: {
    Multiselect
  },
  computed: {
    ...mapGetters("ProjectProfilesStore", ["GetCurrentProfileIntervals"])
  },
  methods: {
    ...mapActions("ProjectProfilesStore", ["GET_ALL_PROJECT_PROFILES", "GET_PROFILE_DETAILS", "GET_PROFILE_INTERVALS"]),
    ...mapActions("projects", {
      DELETE_iNTERVAL: "DELETE_iNTERVAL",
      UPDATE_INTERVAL: "updateInterval"
    })
  }
})
export default class IntervalDetailsTable extends Mixins(confirmModal) {
  readonly GetCurrentProfileIntervals!: Array<Interval>;
  private GET_ALL_PROJECT_PROFILES!: Function;
  private GET_PROFILE_DETAILS!: Function;
  private GET_PROFILE_INTERVALS!: Function;
  private DELETE_iNTERVAL!: Function;
  private UPDATE_INTERVAL!: Function;

  @Prop({ type: Object }) profile!: ProjectProfile;
  @Prop({ type: Number, default: 0 }) profileId!: number;
  @Prop({ type: Boolean, default: false }) isInfoModal!: boolean;
  @Prop({ type: Boolean, default: false }) scaleDown!: boolean;
  @Prop({ type: String, default: "48x48" }) displaySize!: string;

  /*----------  local data  ----------*/
  intervalLabels = [
    {
      key: "Type",
      sortable: false,
      label: this.translateTableHeaderLabel("type")
    },
    {
      key: "active",
      sortable: false,
      label: this.translateTableHeaderLabel("active")
    },
    {
      key: "cbradioEnabled",
      sortable: false,
      label: this.translateTableHeaderLabel("cbradio")
    },
    {
      key: "prewarningEnabled",
      sortable: false,
      label: this.translateTableHeaderLabel("prewarning")
    },
    {
      key: "timeStart",
      sortable: false,
      label: this.translateTableHeaderLabel("timeStart")
    },
    {
      key: "timeEnd",
      sortable: false,
      label: this.translateTableHeaderLabel("timeEnd")
    },
    {
      key: "weekdays",
      sortable: false,
      label: this.translateTableHeaderLabel("weekdays")
    },

    {
      key: "filename",
      sortable: false,
      label: this.translateTableHeaderLabel("filename")
    },
    {
      key: "actions",
      sortable: false,
      label: this.translateTableHeaderLabel("actions")
    }
  ];
  localIntervalsValue: Array<Interval> = [];
  tableInterval: Array<Interval> = [];
  isLoading = false;

  webUrl = process.env.VUE_APP_WEB_URL;

  /*----------  Computed  ----------*/
  get tableHeaderLabels() {
    if (this.isInfoModal) {
      return this.intervalLabels.filter((item) => item.key !== "actions");
    }
    return this.intervalLabels;
  }
  /*=====  End of local data  ======*/

  @Watch("profile", { immediate: true })
  async handleNewProfileChange(profile: any) {
    // get all the interval value
    if (profile.id !== 0 && profile.id) {
      let res = await this.GET_PROFILE_INTERVALS(profile.id);
      if (res.status === 200) {
        this.localIntervalsValue = this.GetCurrentProfileIntervals;
      }
    }
  }

  @Watch("localIntervalsValue", { immediate: true })
  async handleIntervalListChange(intervalArray: Array<Interval>) {
    this.tableInterval = intervalArray.map((interval: any) => {
      let isEmpty = interval.filename === "";
      let translatedEmptyImage = this.$t("project.section.displayInterval.filenames.empty");
      let haveTranslations = new Array(...(this as any).$t("project.section.displayInterval.displaySignOptions")).some(
        (item) => item.value === interval.filename
      );

      if (!haveTranslations) {
        interval.filename = {
          name: isEmpty ? translatedEmptyImage : interval.filename.replace(".bmp", ""),
          value: interval.filename
        };
      } else {
        interval.filename = {
          name: isEmpty
            ? translatedEmptyImage
            : this.$t(`project.section.displayInterval.filenames.${interval.filename.replace(".bmp", "")}`),
          value: interval.filename
        };
      }
      return interval;
    });
  }

  /*----------  Methods  ----------*/
  async handleDeleteInterval(intervalIndex: number) {
    this.$emit("createProfileStatus", null);
    this.confirm(
      this.$t("project.section.displayInterval.confirmModals.msgDeleteIntervalConfirm").toString(),
      async () => {
        let deleteStatus = await this.DELETE_iNTERVAL(intervalIndex);
        if (deleteStatus.status === 204) {
          if (this.profileId) {
            this.GET_PROFILE_DETAILS(this.profileId);
          } else {
            this.GET_ALL_PROJECT_PROFILES();
          }

          this.$emit("createProfileStatus", {
            class: "success",
            msg: this.$t("project.section.displayInterval.confirmModals.msgDeleteIntervalConfirmSuccess")
          });
        } else {
          this.$emit("createProfileStatus", {
            class: "danger",
            msg: deleteStatus
          });
        }
      }
    );
  }

  HandleAddCustomImage(customFilename: string, settings: any) {
    let filename = customFilename.replace(/.bmp/g, "");
    settings.filename = {
      name: filename,
      value: `${filename}.bmp`
    };
    this.handleSingleIntervalUpdate(settings);
  }

  private handleSingleIntervalUpdate(interval: Interval) {
    this.$emit("createProfileStatus", null);
    this.confirm(
      this.$t("project.section.displayInterval.confirmModals.msgToggleIntervalConfirm").toString(),
      async () => {
        this.isLoading = true;
        interval.filename = interval.filename.value;
        interval.profileId = this.profile.id;
        const updateInterval = await this.UPDATE_INTERVAL(interval);

        if (updateInterval.status === 200) {
          this.isLoading = false;
          this.$emit("createProfileStatus", {
            class: "success",
            msg: this.$t("project.section.displayInterval.confirmModals.msgUpdateIntervalConfirmSuccess")
          });
        } else {
          this.isLoading = false;
          this.$emit("createProfileStatus", {
            class: "danger",
            msg: updateInterval
          });
        }
      },
      async () => {
        this.GET_PROFILE_DETAILS(this.profile.id);
      }
    );
  }

  private getPictureUrl(pictureName: string) {
    //var picturePath = "48x48";
    return `${this.webUrl}/img/${this.displaySize}/${pictureName}`;
  }
  /*----------  label translations  ----------*/
  private translateTableHeaderLabel(label: string) {
    return this.$t(`project.profiles.intervalTableLabel.${label}`);
  }
}
